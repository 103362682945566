var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.ready
    ? _c(
        "div",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "9" } },
                [
                  _c("DataTable", {
                    attrs: {
                      items: _vm.vouchers,
                      tableFilter: _vm.tableFilter,
                      fields: [
                        "code",
                        "amount",
                        {
                          key: "voucherScopeType",
                          label: "Scope Type",
                          sorter: false,
                          filter: false
                        },
                        "companyName",
                        "transactionCount",
                        "voucherDiscountType",
                        "percentage",
                        "minimalOrderAmount",
                        {
                          key: "actions",
                          label: "",
                          _style: "width:30%",
                          sorter: false,
                          filter: false
                        }
                      ],
                      "table-filter-value": _vm.tableFilterValue,
                      "sorter-value": _vm.sorterValue,
                      loading: _vm.loading
                    },
                    on: {
                      "update:tableFilterValue": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:table-filter-value": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:sorterValue": function($event) {
                        _vm.sorterValue = $event
                      },
                      "update:sorter-value": function($event) {
                        _vm.sorterValue = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "code",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { class: { deactivated: item.isDeactivated } },
                                [_vm._v(" " + _vm._s(item.code) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "amount",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(item.amount, {
                                        signDisplay: "always"
                                      })
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "companyName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                item.voucherScopeType == "Company"
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(item.companyName) + " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          }
                        },
                        {
                          key: "actions",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        to: {
                                          name: "AdminVoucherEdit",
                                          params: { voucherId: item.id }
                                        }
                                      }
                                    },
                                    [_vm._v(" Editieren ")]
                                  ),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: { color: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onDelete(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("Löschen")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4045186379
                    )
                  }),
                  _c("CPagination", {
                    attrs: { pages: _vm.pages, activePage: _vm.currentPage },
                    on: {
                      "update:activePage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:active-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: 3 } },
                [
                  _c(
                    "CreateCard",
                    {
                      attrs: {
                        loading: _vm.loading,
                        errorMessage: _vm.message,
                        title: _vm.$t(
                          "pages.admin.AdminVoucherIndex.createTitle"
                        ),
                        buttonEnabled: !!_vm.voucher.voucherScopeType
                      },
                      on: { handleSubmit: _vm.handleSubmit }
                    },
                    [
                      _c("ValidatedSelect", {
                        attrs: {
                          translationKey: "voucher.voucherScopeType",
                          field: _vm.$v.voucher.voucherScopeType,
                          options: _vm.availableVoucherScopeTypeOptions,
                          value: _vm.$v.voucher.voucherScopeType.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.voucher.voucherScopeType,
                              "$model",
                              $event
                            )
                          }
                        }
                      }),
                      _c("ValidatedSelect", {
                        attrs: {
                          translationKey: "voucher.voucherDiscountType",
                          field: _vm.$v.voucher.voucherDiscountType,
                          value: _vm.$v.voucher.voucherDiscountType.$model,
                          options: _vm.availableVoucherDiscountTypeOptions
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.voucher.voucherDiscountType,
                              "$model",
                              $event
                            )
                          }
                        }
                      }),
                      _vm.voucher.voucherDiscountType == "Percentage"
                        ? _c("ValidatedInput", {
                            attrs: {
                              translationKey: "voucher.percentage",
                              field: _vm.$v.voucher.percentage,
                              value: _vm.$v.voucher.percentage.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.percentage,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-content",
                                  fn: function() {
                                    return [_vm._v(" % ")]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2419473106
                            )
                          })
                        : _vm._e(),
                      _vm.voucher.voucherDiscountType == "Amount"
                        ? _c("ValidatedInput", {
                            attrs: {
                              translationKey: "voucher.initialAmount",
                              field: _vm.$v.voucher.initialAmount,
                              value: _vm.$v.voucher.initialAmount.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.voucher.initialAmount,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-content",
                                  fn: function() {
                                    return [
                                      _c("CIcon", {
                                        attrs: { name: "cis-euro" }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              802186552
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }