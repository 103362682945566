var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CForm",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", { staticClass: "lead" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _c(
            "CCardBody",
            [
              _c("div", { staticClass: "form-group" }, [
                _vm.errorMessage
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { role: "alert" }
                      },
                      [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                    )
                  : _vm._e()
              ]),
              _vm._t("default")
            ],
            2
          ),
          _c(
            "CCardFooter",
            [
              _c(
                "CButton",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading || !_vm.buttonEnabled,
                    color: "primary"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("forms.common.add")) + " ")]
              )
            ],
            1
          ),
          _vm.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }